import gql from 'graphql-tag'

const checkLoggedIn = (apolloClient) =>
  apolloClient
    .query({
      query: gql`
        {
          me {
            id
            favorites {
              id
              ... on ImageTemplate {
                id
              }
              ... on VideoTemplate {
                id
              }
            }
            username
            avatar
            firstName
            lastName
            email
            role {
              id
              name
            }
            agency {
              id
              active
              businesses {
                id
                name
              }
            }
            business {
              id
              name
            }
            salesAccount {
              id
              logoUrl
              watermarkUrl
              paypalEmail
            }
            statuses {
              id
              name
            }
            createdAt
            plan {
              id
              ... on UserPlanSubscription {
                subscriptionParent {
                  name
                  type {
                    typeName
                  }
                }
              }
            }
            extras {
              id
              ... on UserExtraSubscription {
                subscriptionParent {
                  name
                  type {
                    typeName
                  }
                }
              }
            }
          }
        }
      `
    })
    .then(({ data }) => {
      return { loggedInUser: data.me }
    })
    .catch(() => {
      return { loggedInUser: null }
    })

export default checkLoggedIn
