export const resources = [
  { name: 'Help Center', target: '' },

  { name: 'Pricing', target: '' },

  { name: 'Contact us', target: '' },

  { name: 'Be a contributor', target: '' },

  { name: 'Terms of Use', target: '' },

  { name: 'Privacy Policy', target: '' },

  { name: 'Earnings Disclaimer', target: '' },

  { name: 'Careers', target: '' }
]

export const socials = [
  { name: 'Instagram', target: '' },
  { name: 'Facebook', target: '' },
  { name: 'Twitter', target: '' },
  { name: 'YouTube', target: '' },
  { name: 'Blog', target: '' },
  { name: 'Newsletter', target: '' },
  { name: 'Podcast', target: '' },
  { name: 'Eyeslick', target: '' }
]

export const mostPopular = [
  { name: 'Hotel - Instagram Template', target: '' },
  { name: 'House Choice - Facebook Video Cover / Header', target: '' },
  { name: 'Dentist Calls - Facebook HD Video Template', target: '' },
  { name: 'Road to Success - Live Action Video Template', target: '' },
  { name: 'Modern Estates - Facebook HD Video Template', target: '' },
  { name: 'Summer Adventure - Instagram Vertical Video Template', target: '' },
  { name: 'Dessert - Promo Video for Facebook', target: '' }
]
