import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'
import Router from 'next/router'
import { Link as NextLink } from 'next/link'
import Toolbar from '@material-ui/core/Toolbar'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Link from '@material-ui/core/Link'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'

import { commonLinks } from '../../lib/constants/links'

import AccountMenu from '../AccountMenu'
import MobileMainMenu from '../MobileMainMenu'
import NavDropDown from '../NavDropDown'

import {
  DesktopMainMenuContainer,
  DesktopAccountMenuContainer,
  MobileMenuContainer,
  FlexToolbar,
  TransitionAppBar
} from './Header.styles'

const ElevationScroll = ({ light, children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  })
  let color = 'transparent'
  if (trigger) color = light ? 'default' : 'primary'
  else color = 'transparent'

  return cloneElement(children, {
    elevation: trigger ? 1 : 0,
    color
  })
}

ElevationScroll.propTypes = {
  children: PropTypes.node.isRequired,
  light: PropTypes.bool
}

const Header = ({ user, light }) => {
  const performAction = async (e, action) => {
    if (!action) return

    e.preventDefault()
  }

  const mainMenu = commonLinks.map((item) => {
    const { text, to, dropdown, action, external } = item

    if (dropdown) {
      return <NavDropDown {...item} key={text} />
    }

    const onClickHandler = action
      ? (e) => performAction(e, action)
      : () => Router.push(to)
    const props = external ? { href: to } : { onClick: onClickHandler }

    return (
      <MenuItem key={text} {...props} component={Link} color='inherit'>
        {text}
      </MenuItem>
    )
  })

  return (
    <ElevationScroll light={light}>
      <TransitionAppBar position='fixed'>
        <Toolbar component={FlexToolbar} mode={light ? 'light' : 'dark'}>
          <Grid container>
            <Grid item xs>
              <Link href='/home' component={NextLink}>
                <img
                  src={`/static/images/${
                    light ? 'logo' : 'dropmock_logo_small_white'
                  }.png`}
                  width='166'
                  alt='DropMock'
                />
              </Link>
            </Grid>
            <Grid item xs>
              <DesktopMainMenuContainer>{mainMenu}</DesktopMainMenuContainer>
            </Grid>
            <Grid item xs>
              <DesktopAccountMenuContainer>
                <AccountMenu user={user} />
              </DesktopAccountMenuContainer>
            </Grid>
          </Grid>
          <MobileMenuContainer>
            <MobileMainMenu>
              {mainMenu}
              <Divider />
              <AccountMenu user={user} />
            </MobileMainMenu>
          </MobileMenuContainer>
        </Toolbar>
      </TransitionAppBar>
    </ElevationScroll>
  )
}

export default Header
