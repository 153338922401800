const commonLinks = [
  {
    to: '/',
    text: 'Home',
    showOnPrivate: true
  },
  {
    to: '/templates',
    text: 'Templates',
    dropdown: true,
    showOnPrivate: true
  },
  {
    to: '/dashboard/projects',
    text: 'My Projects',
    showOnPrivate: true
  },
  {
    to: 'https://blog.dropmock.com/',
    text: 'Blog',
    external: true
  }
]

const publicLinks = [
  {
    to: '/login',
    text: 'Login',
    button: true
  }
  // {
  //   to: '/signup',
  //   text: 'Join Us',
  //   button: true,
  //   variant: 'outlined'
  // }
]

const privateLinks = [
  {
    to: '/dashboard',
    text: 'Dashboard'
  },
  {
    to: '/account',
    text: 'Account',
    divider: true
  },
  // {
  //   to: '/addons?view=storage',
  //   text: 'Storage'
  // },
  // {
  //   to: '/addons?view=renders',
  //   text: 'Renders'
  // },
  {
    to: 'https://all-in-one.dropmock.com/tutorials',
    text: 'Tutorials',
    external: true
  },
  {
    to: 'https://all-in-one.dropmock.com/webinars',
    text: 'Webinars',
    external: true
  },
  {
    to: '#',
    text: 'Logout',
    button: true,
    action: 'logout',
    divider: true
  }
]

export { commonLinks, publicLinks, privateLinks }
